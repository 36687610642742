import _ from 'lodash'
import { payBoxUtils, saleItemUtils } from '.'
import discountTypes from '../dominio/discount-types'

const getInitialQuotationItem = () => ({
  id: null,
  localId: null,
  productKitId: null,
  product: null,
  priceTable: { id: null },
  priceTableId: null,
  promotionId: null,
  quotationItemAssociatedId: null,
  skuAssociatedId: null,
  skuTieredPriceId: null,

  orderQuantity: 0,
  quantity: 1,
  maxQuantity: -1,

  unitDiscountAuth: null,

  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  unitDiscount: 0,
  discountPriceTable: 0,

  /**
   * valor unitário na config do produto
   * * caso seja um saleItem já salvo, será o unitValue de quando o item foi salvo pela última vez
   */
  unitValue: 0,
  /** valor mostrado ao usuário na tela de acordo com sua regra de preço (escalonado, promoção, tabela de preço, etc).
   * * já tem o desconto aplicado nele
   */
  price: 0,
  /**
   * valor unitário na config do produto
   */
  originalUnitValue: 0,
  contractualFine: 0,

  grossValue: 0,
  netValue: 0,

  deliveredDate: null,

  createdDate: null,
  createdBy: '',

  lendingProducts: [],
  kitItems: [],

  observation: '',
  inputMethod: null,
  classification: null,
  tieredPrices: [],

  isDeleted: false,
  priceRules: [],
  priceRuleSelected: {},
})

// #region saleItem to product

const buildQuotationItemToProduct = quotationItem => {
  const skuData = quotationItem.sku

  return {
    ...skuData,
    classification: quotationItem.classification,

    skuId: quotationItem.sku.id,
    productId: quotationItem.sku.product?.id || quotationItem.sku.productId,
    productKitId: quotationItem.productKitId,

    skuAssociatedId: quotationItem.skuAssociatedId,

    priceTableId: quotationItem.priceTableId,
    priceTable: quotationItem.priceTable || { id: quotationItem.priceTableId },

    skuTieredPriceId: quotationItem.skuTieredPriceId,
    tieredPrices: quotationItem.tieredPrices,

    promotionId: quotationItem.promotionId,
    promotionQuantityUnlimited:
      skuData?.promotionQuantityUnlimited || quotationItem.promotionQuantityUnlimited,
    promotionQuantityAvailable:
      skuData?.promotionQuantityAvailable || quotationItem.promotionQuantityAvailable,
    promotionalPrice: skuData?.promotionalPrice || quotationItem.promotionalPrice,
    priceFrom: skuData?.priceFrom || quotationItem?.priceFrom,

    quantity: quotationItem.quantity,

    unitValue: quotationItem.unitValue,
    originalUnitValue: quotationItem.originalUnitValue,
    price: quotationItem.unitValue,
    originalPrice: quotationItem.originalUnitValue,
    contractualFine: quotationItem.contractualFine,

    discountType: quotationItem.discountType,
    unitDiscount: quotationItem.unitDiscount,
    discountPriceTable: quotationItem.discountPriceTable,

    label: quotationItem.sku.name,
    hasColdOption: quotationItem.hasColdOption || quotationItem.skuEnriched?.hasColdOption,
  }
}

const buildQuotationItemKitToProduct = (quotationItemKit, kitItems) => {
  const kitItemProducts = kitItems.map(ki => ki.skuProduct)

  return {
    ...quotationItemKit.productKit,
    label: quotationItemKit.productKit.name,

    productKitId: quotationItemKit.productKit?.id,
    kitItems: kitItemProducts,
    kit: true,

    quantity: quotationItemKit.quantity,

    unitValue: quotationItemKit.unitValue,
    originalUnitValue: quotationItemKit.unitValue,
    price: quotationItemKit.unitValue,
    originalPrice: quotationItemKit.unitValue,
  }
}

// #endregion

const quotationItemUtils = {
  // #region SaleItemApi To SaleItem
  buildQuotationItemFromApi(quotationItemFromApi) {
    const initialItem = getInitialQuotationItem()
    const skuProduct = buildQuotationItemToProduct(quotationItemFromApi)

    const priceTable = quotationItemFromApi.priceTable || { id: quotationItemFromApi.priceTableId }

    const quotationItem = {
      ...initialItem,
      ...quotationItemFromApi,
      classification: quotationItemFromApi.classification,

      id: quotationItemFromApi.id,
      quotationItemAssociatedId: quotationItemFromApi.quotationItemAssociatedId,
      skuAssociatedId: quotationItemFromApi.skuAssociatedId,
      skuTieredPriceId: quotationItemFromApi.skuTieredPriceId,
      promotionId: quotationItemFromApi.promotionId,
      productKitId: quotationItemFromApi.productKitId,
      priceTable,

      skuProduct,

      quantity: quotationItemFromApi.quantity,
      orderQuantity: quotationItemFromApi.quantity,
      maxQuantity: quotationItemFromApi.promotionId
        ? quotationItemFromApi.quantity
        : initialItem.maxQuantity,

      discountType: quotationItemFromApi.discountType,
      unitDiscount: quotationItemFromApi.unitDiscount,
      discountPriceTable: quotationItemFromApi.discountPriceTable,

      price: quotationItemFromApi.unitValue,
    }

    const { priceRules } = payBoxUtils.buildPriceRulesFromQuotationItem(quotationItem)
    const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
      discountType: quotationItem.discountType,
      unitDiscount: quotationItem.unitDiscount,
      quantity: quotationItem.quantity,
      promotionId: quotationItem.promotionId,
      priceTableId: quotationItem.priceTable?.id,
      productKitId: quotationItem.productKitId,
      priceRules,
    })

    return { ...quotationItem, priceRuleSelected, priceRules }
  },

  buildQuotationItemKitFromApi(quotationItemKitFromApi, saleKitItems) {
    const initialItem = getInitialQuotationItem()

    const quotationItemKit = {
      ...initialItem,
      ...quotationItemKitFromApi,
      unitValue: quotationItemKitFromApi.unitValue,
      isKit: true,
      skuProduct: buildQuotationItemKitToProduct(quotationItemKitFromApi, saleKitItems),
      quantity: quotationItemKitFromApi.quantity,
      orderQuantity: quotationItemKitFromApi.quantity,
      productKitId: quotationItemKitFromApi.productKit.id,
      kitItems: saleKitItems,
    }

    const { priceRules } = payBoxUtils.buildPriceRulesFromQuotationItem(quotationItemKit)
    const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
      quantity: quotationItemKit.quantity,
      productKitId: quotationItemKit.productKitId,
      priceRules,
    })

    return { ...quotationItemKit, priceRuleSelected, priceRules }
  },

  /**
   * Formatar os itens que vem da API com propriedade skuEnriched para agrupar comodatos associados a itens e itens dentro de kit
   * @param {Array} apiItems items da venda
   * @param {Array} apiItemKits kits da venda
   * @returns
   */
  buildQuotationItemsAndKitsFromApi(apiQuotationItems, apiQuotationItemKits) {
    const itemsMapped = _.sortBy(apiQuotationItems, ['id']).map(quotationItemApi =>
      this.buildQuotationItemFromApi(quotationItemApi)
    )
    const lendingQuotationItems = itemsMapped.filter(
      saleItem => saleItem.quotationItemAssociatedId
    )

    const items = itemsMapped
      .filter(item => !item.productKitId && !item.quotationItemAssociatedId)
      .map(item => {
        const lendingQuotationItem = lendingQuotationItems.find(
          lp => lp.quotationItemAssociatedId === item.id
        )
        let lendingList = []
        if (lendingQuotationItem) {
          lendingList = [lendingQuotationItem]
        }

        return {
          ...item,
          lendingProducts: lendingList,
        }
      })

    const itemKits = apiQuotationItemKits.map(kit => {
      const kitItemsResult = itemsMapped.filter(i => i.productKitId === kit.productKit.id)

      const kitItemsAssociated = kitItemsResult.filter(
        kia => kia.quotationItemAssociatedId != null
      )

      const itemsInKit = kitItemsResult
        .filter(ki => ki.quotationItemAssociatedId == null)
        .map(saleKitItem => {
          const itemConfigQuantity = saleKitItem.quantity / (kit.quantity || 1)

          // cenário: itens do kit possuem comodato associado a outro dentro do kit
          const associatedOfThisKitItem = kitItemsAssociated.find(
            kia => kia.quotationItemAssociatedId === saleKitItem.id
          )

          return {
            ...saleKitItem,
            skuProduct: { ...saleKitItem.skuProduct, quantity: itemConfigQuantity },
            lendingProducts: associatedOfThisKitItem ? [associatedOfThisKitItem] : [],
          }
        })

      return this.buildQuotationItemKitFromApi(kit, itemsInKit)
    })

    return [...items, ...itemKits]
  },
  // #endregion

  // #region refresh saleItem using product
  quotationItemForceRefreshUsingProduct(quotationItem, product) {
    return saleItemUtils.saleItemForceRefreshUsingProduct(quotationItem, product)
  },

  // #endregion
}

export default quotationItemUtils
