var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mxIsDevelopment
    ? _c("div", [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.openDevModal()
              },
            },
          },
          [_vm._v(" Abrir dev config ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }