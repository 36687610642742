import { sortBy } from 'lodash'
import { roundDecimal } from '@/utils/number-utils'
import { Decimal } from 'decimal.js-light'
import { discountTypes } from '../dominio'

const getInitialPriceRule = () => ({
  priceTable: null,
  productKit: null,
  promotion: null,
  tieredPrice: null,
  itemReturned: null,
  priceInfo: {
    unitValue: 0,
    originalPrice: 0,
    price: 0,
    contractualFine: 0,
    promotionalPriceFrom: 0,

    unitDiscount: 0,
    discountType: discountTypes.computed.discountTypesEnum().VALUE,
    unitDiscountValue: 0,

    localDiscountTotal: 0,
    localPriceTableDiscountTotal: 0,
    localGrossValue: 0,
    localNetValue: 0,
  },
})

const payBoxUtils = {
  priceRuleTypes: {
    TIERED_PRICE: 'TIERED_PRICE',
    PROMOTION: 'PROMOTION',
    PRICE_TABLE: 'PRICE_TABLE',
    KIT: 'KIT',
    KIT_ITEM: 'KIT_ITEM',
    RETURNED_ITEM: 'RETURNED_ITEM',
  },

  calculateItemPriceOld({
    price,
    originalPrice,
    discountType,
    unitDiscount,
    quantity,
    priceTableDiscount,
    promotionId,
    tieredPrices,
  }) {
    const { PERCENTAGE, VALUE } = discountTypes.computed.discountTypesEnum()
    const tiererPrice = this.getTieredPrice(quantity, tieredPrices)
    const localPrice = tiererPrice?.price || price !== originalPrice ? price : originalPrice

    let netValue = localPrice * quantity
    let totalDiscount = unitDiscount || 0

    // O arredondamento dos descontos para 2 casas decimais deve ser feito sempre antes de multiplicar pela quantidade.
    let unitDiscountValue = unitDiscount
    if (unitDiscount) {
      if (discountType === PERCENTAGE) {
        unitDiscountValue = new Decimal(localPrice)
          .times(unitDiscount || 0)
          .toDecimalPlaces(2)
          .toNumber()
        totalDiscount = unitDiscountValue * quantity
      } else if (discountType === VALUE) {
        totalDiscount = unitDiscount * quantity
      }
    }

    const localPriceTableDiscount = priceTableDiscount || 0
    const isToApplyPriceTableDiscount = !promotionId && priceTableDiscount > 0
    let priceTableDiscountValue = 0
    if (isToApplyPriceTableDiscount) {
      priceTableDiscountValue = new Decimal(localPrice)
        .times(localPriceTableDiscount || 0)
        .toDecimalPlaces(2)
        .toNumber()
      totalDiscount += priceTableDiscountValue * quantity
    }

    totalDiscount = roundDecimal(totalDiscount, 2)
    const totalUnitDiscount = roundDecimal(unitDiscountValue + priceTableDiscountValue, 2)

    netValue -= totalDiscount
    return {
      netValue: roundDecimal(netValue),
      netUnitDiscount: totalUnitDiscount,
      priceInfo: {
        price: localPrice,
        originalPrice: originalPrice ?? price,
        priceWithDiscount: roundDecimal(localPrice - totalUnitDiscount),
        discountTotal: totalDiscount,
        priceTableDiscountTotal: roundDecimal(priceTableDiscountValue * quantity),
        grossValue: roundDecimal(localPrice * quantity),
        skuTieredPriceId: tiererPrice?.id,
      },
    }
  },

  calculateItemPrice({
    discountType,
    unitDiscount,
    quantity,
    promotionId,
    priceTableId,
    productKitId,
    itemReturnedId,
    priceRules,
    forcedPriceRuleType,
  }) {
    const localQuantity = quantity || 0
    const { priceRuleSelected } = this.getPriceRule(
      {
        quantity: localQuantity,
        promotionId,
        priceTableId,
        productKitId,
        itemReturnedId,
        forcedPriceRuleType,
      },
      priceRules
    )
    const localUnitValue = priceRuleSelected.priceInfo?.unitValue

    const netValue = new Decimal(localUnitValue).mul(localQuantity)
    let totalDiscount = new Decimal(0)
    let unitDiscountValue = new Decimal(0)
    let unitPriceTableDiscountValue = new Decimal(0)
    let totalPriceTableDiscount = new Decimal(0)

    // O arredondamento dos descontos para 2 casas decimais deve ser feito sempre antes de multiplicar pela quantidade.

    if (unitDiscount > 0) {
      unitDiscountValue = unitDiscountValue.add(
        this.getDiscountValue({
          value: localUnitValue,
          discount: unitDiscount,
          discountType,
        }) || 0
      )
      totalDiscount = totalDiscount.add(
        new Decimal(unitDiscountValue).toDecimalPlaces(2).mul(localQuantity)
      )
    }

    if (this.canApplyPriceTableDiscount(priceRuleSelected)) {
      unitPriceTableDiscountValue = priceRuleSelected.priceTable.priceTableDiscountValue || 0

      totalPriceTableDiscount = totalPriceTableDiscount.add(
        new Decimal(unitPriceTableDiscountValue || 0).toDecimalPlaces(2).mul(localQuantity)
      )

      totalDiscount = totalDiscount.add(totalPriceTableDiscount)
    }

    priceRuleSelected.priceInfo = {
      ...priceRuleSelected.priceInfo,
      discountType,
      unitDiscount,
      unitDiscountValue: unitDiscountValue
        .add(unitPriceTableDiscountValue)
        .toDecimalPlaces(2)
        .toNumber(),

      price: new Decimal(priceRuleSelected.priceInfo.unitValue)
        .minus(unitPriceTableDiscountValue)
        .minus(unitDiscountValue)
        .toDecimalPlaces(2)
        .toNumber(),

      localDiscountTotal: totalDiscount.toDecimalPlaces(2).toNumber(),
      localPriceTableDiscountTotal: totalPriceTableDiscount.toDecimalPlaces(2).toNumber(),

      localGrossValue: new Decimal(localUnitValue).mul(localQuantity).toDecimalPlaces(2).toNumber(),
      localNetValue: netValue
        .minus(totalDiscount)
        .minus(priceRuleSelected.productKit?.localReturnedKitNetValue || 0)
        .toDecimalPlaces(2)
        .toNumber(),
    }

    return {
      priceRuleSelected,
    }
  },

  canApplyPriceTableDiscount(priceRuleSelected) {
    const rulesThatCanApplyPriceTableDiscount = [
      this.priceRuleTypes.PRICE_TABLE,
      this.priceRuleTypes.TIERED_PRICE,
    ]

    return (
      rulesThatCanApplyPriceTableDiscount.includes(priceRuleSelected?.type) &&
      priceRuleSelected?.priceTable?.discount > 0
    )
  },

  buildSaleItemReturnPriceRules(saleItemReturned) {
    return this.buildPriceRules({
      ...saleItemReturned,
      itemReturnedId: saleItemReturned.itemReturnedId,
      isReturnedItem: true,

      unitValue: saleItemReturned.unitValue,
      price: saleItemReturned.unitValue,
      originalPrice: saleItemReturned.unitValue,
      contractualFine: saleItemReturned.skuProduct?.contractualFine,

      quantity: saleItemReturned.quantity,
    })
  },

  buildPriceRulesFromSaleItem(saleItem) {
    return this.buildPriceRules({
      ...saleItem,
      unitValue: saleItem.unitValue,
      price: saleItem.unitValue,
      originalPrice: saleItem.originalUnitValue || saleItem.unitValue,
      priceTable: {
        ...saleItem.priceTable,
        discount: saleItem.discountPriceTable,
      },

      tieredPrices: saleItem.skuProduct?.tieredPrices,

      promotionQuantityUnlimited: saleItem?.skuProduct?.promotionQuantityUnlimited,
      promotionQuantityAvailable: saleItem?.skuProduct?.promotionQuantityAvailable,
      promotionalPrice: saleItem?.skuProduct?.promotionalPrice,
      priceFrom: saleItem?.skuProduct?.priceFrom,

      localReturnedKitNetValue: saleItem?.localReturnedKitNetValue,
    })
  },

  buildPriceRulesFromQuotationItem(quotationItem) {
    return this.buildPriceRules({
      ...quotationItem,
      unitValue: quotationItem.unitValue,
      price: quotationItem.unitValue,
      originalPrice: quotationItem.originalUnitValue || quotationItem.unitValue,
      priceTable: {
        ...quotationItem.priceTable,
        discount: quotationItem.discountPriceTable,
      },

      tieredPrices: quotationItem.skuProduct?.tieredPrices,

      promotionQuantityUnlimited: quotationItem?.skuProduct?.promotionQuantityUnlimited,
      promotionQuantityAvailable: quotationItem?.skuProduct?.promotionQuantityAvailable,
      promotionalPrice: quotationItem?.skuProduct?.promotionalPrice,
      priceFrom: quotationItem?.skuProduct?.priceFrom,
    })
  },

  buildPriceRulesFromProduct(product) {
    let unitValue = product.originalPrice || product.price
    if (product.kitItems?.length > 0) {
      unitValue = product.price
    }

    return this.buildPriceRules({
      ...product,
      unitValue,
    })
  },

  buildPriceRules({
    unitValue,
    price,
    originalPrice,
    contractualFine,

    quantity,
    unitDiscount,
    discountType,

    priceTable,

    productKitId,
    kitItems,

    promotionId,
    promotionQuantityUnlimited,
    promotionQuantityAvailable,
    promotionalPrice,
    priceFrom,

    skuTieredPriceId,
    tieredPrices,

    itemReturnedId,
    isReturnedItem,
    localReturnedKitNetValue,
  }) {
    const priceRules = []

    const defaultPriceRules = {
      ...getInitialPriceRule(),
      priceTable,
      productKit: null,
      promotion: null,
      tieredPrice: null,
      itemReturned: null,
      priceInfo: {
        unitValue,
        originalPrice,
        price,
        contractualFine: contractualFine || 0,
        promotionalPriceFrom: 0,

        unitDiscount,
        discountType,
        unitDiscountValue: 0,
      },
    }

    if (isReturnedItem) {
      const returnedDiscountValue = this.getDiscountValue({
        discount: unitDiscount,
        discountType,
        value: unitValue,
      })

      priceRules.push({
        ...defaultPriceRules,
        type: this.priceRuleTypes.RETURNED_ITEM,
        itemReturned: {
          id: itemReturnedId,
          quantityReturned: quantity,
        },
        priceInfo: {
          ...defaultPriceRules.priceInfo,
          unitDiscountValue: returnedDiscountValue,
        },
      })

      return { priceRules }
    }

    if (kitItems?.length > 0) {
      priceRules.push({
        ...defaultPriceRules,
        type: this.priceRuleTypes.KIT,
        productKit: {
          id: productKitId,
          localReturnedKitNetValue,
        },
        priceInfo: {
          ...defaultPriceRules.priceInfo,
          originalPrice: price,
        },
      })

      return { priceRules }
    }

    if (productKitId) {
      const unitDiscountValue =
        this.getDiscountValue({
          discount: unitDiscount,
          discountType,
          value: price,
        }) || 0

      priceRules.push({
        ...defaultPriceRules,
        type: this.priceRuleTypes.KIT_ITEM,
        productKit: {
          id: productKitId,
          itemQuantity: quantity,
        },
        priceTable: {
          id: 1,
          discount: 0,
        },
        priceInfo: {
          ...defaultPriceRules.priceInfo,
          originalPrice: price,
          unitDiscountValue,
        },
      })

      return { priceRules }
    }

    if (tieredPrices?.length > 0) {
      priceRules.push(
        ...tieredPrices
          .filter(tiered => tiered.id !== skuTieredPriceId)
          .map(tiered => {
            const { discountPriceTableToApply, priceTableDiscountValue, priceResult } =
              this.getPriceTableDiscountResults(defaultPriceRules.priceTable, tiered.price)

            return {
              ...defaultPriceRules,
              priceTable: {
                ...defaultPriceRules.priceTable,
                discount: discountPriceTableToApply,
                priceTableDiscountValue,
              },
              type: this.priceRuleTypes.TIERED_PRICE,
              tieredPrice: tiered,
              priceInfo: {
                ...defaultPriceRules.priceInfo,
                unitValue: tiered.price,
                price: priceResult,
              },
            }
          })
      )
    }

    // cenário de item que já possui preço escalonado aplicado
    if (skuTieredPriceId) {
      const { discountPriceTableToApply, priceTableDiscountValue, priceResult } =
        this.getPriceTableDiscountResults(defaultPriceRules.priceTable, unitValue)

      priceRules.push({
        ...defaultPriceRules,
        type: this.priceRuleTypes.TIERED_PRICE,
        priceTable: {
          ...defaultPriceRules.priceTable,
          discount: discountPriceTableToApply,
          priceTableDiscountValue,
        },
        tieredPrice: {
          id: skuTieredPriceId,
          // preenchendo quantityFrom e quantityTo pra que quando carregue a tela selecione essa regra de preço
          quantityFrom: (quantity || 0) - 1,
          quantityTo: (quantity || 0) + 1,
        },
        priceInfo: {
          ...defaultPriceRules.priceInfo,
          price: priceResult,
        },
      })
    }

    if (promotionId) {
      const promotionMaxQuantity = promotionQuantityUnlimited ? null : promotionQuantityAvailable

      priceRules.push({
        ...defaultPriceRules,
        type: this.priceRuleTypes.PROMOTION,
        promotion: {
          id: promotionId,
          promotionQuantityAvailable,
          promotionQuantityUnlimited,
          promotionalPrice: promotionalPrice || unitValue,
          priceFrom,
        },
        maxQuantity: promotionMaxQuantity,
        priceInfo: {
          ...defaultPriceRules.priceInfo,
          unitValue: promotionalPrice || unitValue,
          price: promotionalPrice || unitValue,
        },
      })
    }

    if (priceTable?.id) {
      const { discountPriceTableToApply, priceTableDiscountValue, priceResult } =
        this.getPriceTableDiscountResults(priceTable, originalPrice)

      priceRules.push({
        ...defaultPriceRules,
        type: this.priceRuleTypes.PRICE_TABLE,
        priceTable: {
          ...priceTable,
          discount: discountPriceTableToApply,
          priceTableDiscountValue,
        },
        priceInfo: {
          ...defaultPriceRules.priceInfo,
          unitValue: originalPrice,
          price: priceResult,
          unitDiscountValue: priceTableDiscountValue,
        },
      })
    }

    return { priceRules }
  },

  getPriceTableDiscountResults(priceTable, value) {
    const discountPriceTableToApply = priceTable.discount || 0

    const priceTableDiscountValue =
      this.getDiscountValue({
        discount: discountPriceTableToApply,
        discountType: discountTypes.computed.discountTypesEnum().PERCENTAGE,
        value,
      }) || 0

    const priceResult = new Decimal(value).minus(priceTableDiscountValue).toNumber()
    return { discountPriceTableToApply, priceTableDiscountValue, priceResult }
  },

  getPriceRule(
    { quantity, promotionId, priceTableId, productKitId, forcedPriceRuleType },
    priceRules
  ) {
    const priceRulesClone = JSON.parse(JSON.stringify(priceRules ?? []))

    if (forcedPriceRuleType) {
      if (typeof forcedPriceRuleType === 'object') {
        return {
          priceRuleSelected: forcedPriceRuleType,
        }
      }

      return {
        priceRuleSelected: priceRulesClone.find(rule => rule.type === forcedPriceRuleType),
      }
    }

    if (productKitId) {
      return {
        priceRuleSelected: priceRulesClone.find(rule => rule.productKit.id === productKitId),
      }
    }

    const tieredPriceRules = priceRulesClone.filter(
      rule => rule.type === this.priceRuleTypes.TIERED_PRICE && rule.tieredPrice?.id
    )

    const tieredPriceApplied = tieredPriceRules.find(rule => {
      const { quantityFrom, quantityTo } = rule.tieredPrice

      return quantity >= quantityFrom && (quantityTo === null || quantity <= quantityTo)
    })

    const promotionPriceRule = promotionId
      ? priceRulesClone.find(
          rule =>
            rule.type === this.priceRuleTypes.PROMOTION && rule.promotion?.id === promotionId
        )
      : null

    const priceTableRule = priceRulesClone.find(
      rule =>
        rule.type === this.priceRuleTypes.PRICE_TABLE && rule.priceTable?.id === priceTableId
    )

    const listWithPriceRules = [priceTableRule]

    if (promotionPriceRule) {
      listWithPriceRules.push(promotionPriceRule)
    }

    if (tieredPriceApplied) {
      listWithPriceRules.push(tieredPriceApplied)
    }

    const priceRuleSelected = sortBy(listWithPriceRules, priceRule => priceRule.priceInfo?.price)

    return { priceRuleSelected: priceRuleSelected[0] }
  },

  getDiscountValue({ discountType, discount, value }) {
    const { PERCENTAGE, VALUE } = discountTypes.computed.discountTypesEnum()
    let result = new Decimal(0)
    if (discountType === VALUE) {
      result = result.add(discount || 0)
    } else if (discountType === PERCENTAGE) {
      result = result.add(new Decimal(value).times(discount || 0).toNumber())
    }

    return result.toDecimalPlaces(2).toNumber() // roundDecimal(result, 2)
  },

  /**
   *
   * @param {Object} item
   * @param {Number} item.unitValue
   * @param {Number} item.unitDiscount
   * @param {Number} item.quantity
   * @param {String} item.discountType
   * @returns
   */
  formatSimpleSaleItem(item) {
    const { priceInfo } = this.calculateItemPriceOld({
      price: item.unitValue,
      unitDiscount: item.unitDiscount,
      quantity: item.quantity,
      discountType: item.discountType,
      promotionId: item.promotionId,
      priceTableDiscount: item.discountPriceTable,
    })

    return {
      ...item,
      price: priceInfo.priceWithDiscount,
      discountTotalValue: priceInfo.discountTotal,
      name: item.name || item.sku?.name || '',
    }
  },

  getTieredPrice(quantity, tieredPrices) {
    return tieredPrices?.find(
      price =>
        quantity >= price.quantityFrom &&
        (price.quantityTo === null || quantity <= price.quantityTo)
    )
  },
}

export default payBoxUtils
