<template>
  <b-modal
    id="modal_dev_settings"
    title="DEV CONFIGS"
    no-close-on-backdrop
    hide-header-close
    size="xl"
    centered
    scrollable
    @hidden="hideModal"
  >
    <FormulateForm
      ref="formDevSettings"
      name="formDevSettings"
    >
      <b-container>
        <b-card-actions
          :title="$t('PDV configs')"
          no-actions
        >
          <b-col md="12">
            <e-paybox-combo
              id="filter-payboxes"
              show-label
              label="DEV (use-paybox-id)"
              style="min-width: 350px"
              :value="currentPayboxConfiguration.id ? currentPayboxConfiguration.id.toString() : null"
              :required="false"
              :placeholder="$t('Config Mac Address')"
              @input="onUsePaybox"
            />
          </b-col>
        </b-card-actions>

        <b-card-actions
          :title="$t('Indexed Db venda')"
          no-actions
        >
          <b-col
            md="12"
            class="d-flex"
          >
            <div class="w-100">
              <FormulateInput
                id="devSaleStored"
                v-model="formData.pdvIdb.saleStored"
                name="devSaleStored"
                rowspan="3"
                type="textarea"
                label="Venda atual"
                readonly
              />
            </div>
            <div>
              <e-button
                :id="`devModal-reloadIndexedDbSale`"
                tabindex="-1"
                size="sm"
                variant="primary"
                icon="arrow-clockwise"
                class="ml-2"
                @click="() => loadSaleIndexedDb()"
              />
            </div>
          </b-col>

          <b-col
            md="12"
            class="d-flex"
          >
            <div class="w-100">
              <FormulateInput
                id="devSaleToUpdate"
                v-model="formData.pdvIdb.saleToUpdate"
                name="devSaleToUpdate"
                rowspan="5"
                type="textarea"
                label="Venda a ser atualizada (colocar json no input)"
              />
            </div>
            <div>
              <e-button
                :id="`devModal-reloadIndexedDbSale`"
                tabindex="-1"
                size="sm"
                text="Atualizar"
                variant="primary"
                class="ml-2"
                @click="() => updateSaleIndexedDb()"
              />
            </div>
          </b-col>
        </b-card-actions>

        <b-card-actions
          :title="$t('Código de barras')"
          no-actions
        >
          <div>
            <FormulateInput
              id="devBarcode"
              v-model="formData.barcode.code"
              name="devBarcode"
              type="text"
              label="Código de barras a ser escaneado"
              instruction="Há casos que não dá pra digitar no campo por causa de bloqueio de teclado, mas dá pra abrir o modal onde é possível digitar e salvar no sessionStorage, na próxima vez que abrir o modal o valor estará salvo."
            />
            <e-button
              id="test-btn_barcode_local_storage"
              :text="$t('salvar na sessionStorage')"
              size="sm"
              variant="outline-primary"
              class="mr-2"
              @click="saveBarcodeOnSessionStorage"
            />
            <e-button
              id="test-btn_barcode_scan"
              :text="$t('Simular scanner')"
              size="sm"
              variant="warning"
              @click="() => simulateScan(formData.barcode.code)"
            />
          </div>
        </b-card-actions>
      </b-container>
    </FormulateForm>

    <template #modal-footer>
      <b-row class="w-100 d-flex justify-content-between align-items-center">
        <b-col
          v-if="errorMessage"
          class="text-danger"
        >
          {{ errorMessage }}
        </b-col>
        <b-col class="d-flex justify-content-end">
          <e-button
            id="modal_dev_settings-btn_cancel"
            class="mr-1"
            variant="outline-primary"
            :text="$t('Voltar')"
            :text-shortcuts="['ESC']"
            @click="hideModal"
          />
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { EPayboxCombo } from '@/views/components'
import { devUtils, payboxConfiguration, loadingOverlay, scanBarcode } from '@/mixins'
import { mapGetters } from 'vuex'
import idbInstance from '@/libs/dexie'

const storageKeys = {
  barcode: 'devBarcode'
}

const defaultEanCode = '78936683'

const getInitialDevForm = () => ({
  pdvIdb: {
    saleStored: '',
    saleToUpdate: '',
  },
  barcode: {
    code: defaultEanCode,
  }
})

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    EButton,
    BCardActions,
    EPayboxCombo,
  },

  mixins: [devUtils, payboxConfiguration, loadingOverlay, scanBarcode],

  props: {},

  data() {
    return {
      localBusy: false,
      formData: getInitialDevForm(),
      errorMessage: ''
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', ['currentPayboxConfiguration']),
  },

  methods: {
    hideModal() {
      this.resetModal()
      this.$bvModal.hide('modal_dev_settings')
    },
    async showModal() {
      if (!this.mxIsDevelopment) {
        this.showWarning('Ação só pode ser feita no ambiente de staging/desenvolvimento')
        return
      }

      this.$bvModal.show('modal_dev_settings')

      this.loadInputDatas()
    },

    loadInputDatas() {
      const barCode = window.sessionStorage.getItem(storageKeys.barcode)
      if (barCode) {
        this.formData.barcode.code = barCode
      }
    },

    resetModal() {
      this.formData = getInitialDevForm()
      this.cleanError()
    },
    cleanError() {
      this.errorMessage = ''
    },

    async onUsePaybox(value) {
      await new Promise(resolve => setTimeout(() => resolve()), 300)
      const valueNumber = Number(value)

      localStorage.removeItem('devOnlyUsePayboxId')
      if (valueNumber) {
        localStorage.setItem('devOnlyUsePayboxId', value)
      }

      if (!valueNumber || valueNumber === Number(this.currentPayboxConfiguration.id)) {
        return
      }

      await this.$router.push({ name: 'home' }) // evicting "NavigationDuplicated: Avoided redundant navigation to current location: /pdv/caixa"
      await this.mxPdvRouteRedirect()
    },

    saveBarcodeOnSessionStorage() {
      window.sessionStorage.setItem(storageKeys.barcode, this.formData.barcode.code || defaultEanCode)
      this.showSuccess({ title: "Salvo" })
    },

    async loadSaleIndexedDb() {
      const payBoxCurrentSaleIdb = idbInstance.table('payBoxCurrentSale')
      const payBoxStorage = await payBoxCurrentSaleIdb.limit(1).toArray()
      const data = payBoxStorage[0]
      this.formData.pdvIdb.saleStored = JSON.stringify(data)
    },
    async updateSaleIndexedDb() {
      this.cleanError()

      try {
        const payBoxCurrentSaleIdb = idbInstance.table('payBoxCurrentSale')
        const data = JSON.parse(this.formData.pdvIdb.saleToUpdate)

        await payBoxCurrentSaleIdb.clear()
        await payBoxCurrentSaleIdb.put(data)

        this.formData.pdvIdb.saleToUpdate = ""
        this.showSuccess({})
      } catch (error) {
        this.errorMessage = 'erro ao atualizar o indexedDb, para mais info ver o console'
        throw error
      }
    }
  },
}
</script>

<style lang="scss" scoped>
#modal_dev_settings___BV_modal_outer_ {
  z-index: 2000 !important;
}
</style>
