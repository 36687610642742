var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "h-100",
      class: [_vm.skinClasses, { "atm-behavior": _vm.applyAtmBehavior }],
      attrs: { id: "app" },
    },
    [
      _c(
        _vm.layout,
        { tag: "component" },
        [_c("router-view", { key: _vm.$route.fullPath })],
        1
      ),
      _c("e-overlay", {
        attrs: {
          show: _vm.loadingOverlay.show,
          cancelable: _vm.loadingOverlay.cancelable,
          text: _vm.loadingOverlay.text,
          title: _vm.loadingOverlay.title,
          image: _vm.loadingOverlay.image,
        },
      }),
      _c("e-modal-authorization", { ref: "appModalAuthorization" }),
      _vm.isVisible
        ? _c("e-simple-keyboard", {
            ref: "globalSimpleKeyboard",
            attrs: { type: _vm.layoutType },
            on: { input: _vm.onKeyboardInput },
          })
        : _vm._e(),
      _c("e-app-idle"),
      _vm.mxIsDevelopment
        ? _c("dev-settings-modal", { ref: "devSettingsModal" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }