import { payBoxTypes, saleItemUtils } from '@/mixins'
import isElectron from 'is-electron'
import axiosInstance from '@/libs/axios'
import catalog from './atm-catalog'
import status from './atm-status'

export const atmScreenEnum = {
  CATALOG: 'catalog',
  PAYMENT: 'payment',
}

const getInitialBaseData = () => ({
  bannersAtm: [],
})

const getInitialState = () => ({
  baseData: getInitialBaseData(),
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {
    catalog,
    status,
  },

  getters: {
    getBannersAtm(state) {
      return state.baseData.bannersAtm || []
    },
  },

  mutations: {
    SET_BANNERS_ATM(state, banners) {
      state.baseData.bannersAtm = banners
    },

    CLEAN_BASE_DATA(state) {
      state.baseData = getInitialBaseData()
    },
    CLEAN_STATE(state) {
      const defaultData = getInitialState()

      const cleanIgnore = ['baseData']
      Object.keys(defaultData).forEach(key => {
        if (cleanIgnore.includes(key)) return
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    async initAtm({ dispatch }) {
      await dispatch('pages/pdv/atm/status/loadAtmStatus', {}, { root: true })
      await dispatch('pages/pdv/atm/catalog/loadAtmCatalog', {}, { root: true })
      await dispatch('pages/pdv/loadAtmPaymentMethods', {}, { root: true })
      await dispatch('fetchBannersAtm')
    },

    async fetchBannersAtm({ commit }) {
      if (!isElectron()) return

      try {
        // Não pega os dados do state pois no login ainda não existe a info.
        const payBoxData = await window.electronAPI.system.identifyPayboxRead()
        const systemInfo = await window.electronAPI.system.systemInfo()

        const payBoxIdentity = payBoxData?.guid || systemInfo?.macAddress || ''
        const { data } = await axiosInstance.get(
          `/api/sales/pay-box-banner/atm/${payBoxIdentity}`,
          {
            params: { pageSize: 3 },
          }
        )

        commit('SET_BANNERS_ATM', data.results || [])
      } catch (error) {
        console.error('[Atm] fetchBannersAtm', error)
      }
    },

    async addItemFromAtm({ dispatch }, { amount, ean, skuId }) {
      const product = await dispatch(
        'pages/pdv/atm/catalog/fetchProductToCart',
        { ean, skuId },
        { root: true }
      )

      const cartSaleItem = saleItemUtils.buildProductToSaleItem(product, {
        quantity: amount,
        inputMethod: payBoxTypes.data().productInputMethodEnum.TYPED,
      })

      await dispatch(
        'pages/pdv/payBox/addItem',
        {
          item: cartSaleItem,
        },
        { root: true }
      )
    },
    async removeItemFromAtm({ dispatch }, item) {
      await dispatch('pages/pdv/payBox/removeItem', item, { root: true })
    },
    async updateItemFromAtm({ dispatch }, { item, amount }) {
      await dispatch(
        'pages/pdv/payBox/updateItem',
        { payload: { ...item, quantity: amount } },
        { root: true }
      )
    },

    async cleanState({ commit, dispatch }, { cleanAll } = { cleanAll: false }) {
      await dispatch('pages/pdv/payBox/cleanState', {}, { root: true })
      await dispatch('pages/pdv/atm/status/cleanState', {}, { root: true })
      commit('CLEAN_STATE')

      if (cleanAll) {
        commit('CLEAN_BASE_DATA')
      }
    },
  },
}
