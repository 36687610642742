import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import moment from 'moment'

const getInitialState = () => ({
  ...storePagination.state(),
  sales: [],
  filters: {
    saleId: '',
    dates: {
      startDate: moment().startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
    customer: null,
    origin: null,
    productClassification: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_SALES(state, sales) {
      state.sales = sales
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, sales } = getInitialState()
      state.filters = filters
      state.sales = sales
      state.paging = paging
    },
    CLEAN_PAGING(state) {
      state.paging = getInitialState().paging
    },
  },

  actions: {
    ...storePagination.actions,

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },
    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    cleanPaging({ commit }) {
      commit('CLEAN_PAGING')
    },

    async fetchSalesWithReclaimProducts({ state, commit }) {
      const { dates, customer, saleId, origin, productClassification, storeId, skuId } =
        state.filters

      const { data } = await axios.get('/api/sales/with-reclaim-products', {
        params: {
          id: saleId,
          origin,
          storeId,
          skuId,
          productClassification,
          customerId: customer,
          issueDateFrom: dates.startDate,
          issueDateTo: dates.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_SALES', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
    },

    async saveReclaimItems(state, { returnedForm }) {
      const itemsReclaim = returnedForm.returnedLendingItems
        .filter(rli => rli.willReturn)
        .map(rli => ({
          id: rli.saleItemId,
          quantityReturned: rli.quantity,
          returnQuantityDamaged: rli.returnQuantityDamaged,
        }))

      const payload = {
        itemsReclaim,
      }
      await axios.put('/api/sales/pay-box-reclaim-items', payload)
    },
  },
}
