import axios from '@axios'

const getInitialState = () => ({
  creditProducts: [],
})

export default {
  namespaced: true,

  state: getInitialState(),

  getters: {
    getCreditProducts(state) {
      return state.creditProducts
    },
    getProduct(state) {
      return state.singleProduct
    },
  },

  mutations: {
    SET_CREDIT_PRODUCTS(state, creditProducts) {
      state.creditProducts = creditProducts
    },

    CLEAN_STATE(state) {
      const { creditProducts } = getInitialState()
      state.creditProducts = creditProducts
    },
  },

  actions: {
    async fetchCreditProducts({ commit, rootGetters, rootState }, { isRecharge, isForGiftCard }) {
      const storeId =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']?.store?.id

      const { data } = await axios.get(
        `/api/sales/pay-box-sku/store/${storeId}/credits?isRecharge=${!!isRecharge}&isForGiftCard=${isForGiftCard}`
      )
      // TODO mock listagem dos cartões de chope
      // const data = { results: [{ name: 'crédito 1 real', ean: '111222333444' }], rowCount: 0, rowsInCurrentPage: 1 }
      data.forEach(productKit => {
        const creditKitItem = productKit.kitItems.find(
          kitItem => kitItem.classification === 'Credit'
        )
        if (rootState.pages.pdv.payBox.choppGiftCardData?.number) {
          creditKitItem.quantity =
            rootState.pages.pdv.payBox.choppGiftCardData?.value / creditKitItem.price
          // creditKitItem.price = rootState.pages.pdv.payBox.choppGiftCardData?.value
        }
        // eslint-disable-next-line no-param-reassign
        productKit.credit = (creditKitItem?.quantity || 0) * creditKitItem.price
      })

      commit('SET_CREDIT_PRODUCTS', data)
    },

    validateCreditKit(_, { product, getItems, itemFormAmount }) {
      const hasExistingAccession = getItems.some(
        item => item.skuProduct.isCredit && item.skuProduct.creditType === 'Accession'
      )
      const hasExistingRecharge = getItems.some(
        item => item.skuProduct.isCredit && item.skuProduct.creditType === 'Recharge'
      )

      if (product.isCredit) {
        if (hasExistingAccession && product.creditType === 'Recharge') {
          return {
            isValid: false,
            message: 'Você não pode adicionar um kit de recarga se já houver um kit de adesão.',
          }
        }
        if (hasExistingRecharge && product.creditType === 'Accession') {
          return {
            isValid: false,
            message: 'Você não pode adicionar um kit de adesão se já houver um kit de recarga.',
          }
        }
        if (product.creditType === 'Accession' && itemFormAmount > 1) {
          return { isValid: false, message: 'Você só pode adicionar um kit de adesão uma vez.' }
        }
        if (hasExistingAccession && product.creditType === 'Accession') {
          return {
            isValid: false,
            message: 'Você só pode adicionar um kit de crédito por cliente.',
          }
        }
      }

      return { isValid: true }
    },

    cleanProductsChoppCard({ commit }) {
      commit('SET_CREDIT_PRODUCTS', [])
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
