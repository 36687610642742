<template>
  <transition name="fade">
    <div
      v-if="show"
      class="blockPage loading-overlay"
    >
      <!-- :show="show" -->
      <div class="text-center">
        <div class="logo-animation">
          <b-img
            class="img rounded"
            :src="logoImg"
            alt="Logo da echope"
          />
        </div>

        <p
          v-if="title"
          id="e-overlay-loading-label"
          class="h4 mt-1 loading-overlay-label"
        >
          {{ title }}
        </p>
        <br v-if="title">

        <p
          id="e-overlay-loading-label"
          class="h3 mt-1 loading-overlay-label"
        >
          {{ text || $t('Carregando') }}
          <span class="one">.</span>
          <span class="two">.</span>
          <span class="three">.</span>
        </p>
        <b-container
          v-if="image != null"
          class="overlay-img-container"
        >
          <b-img
            :src="image"
            fluid-grow
          />
        </b-container>

        <div v-if="isCancelable">
          <e-button
            class="mr-1"
            variant="outline-primary"
            :text="$t('Cancelar')"
            :text-shortcuts="['ESC']"
            @click="onCancel"
          />
        </div>

        <div
          v-if="mxIsDevelopment"
          class="d-flex column align-items-center mt-2"
        >
          <dev-button />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { BImg, BContainer } from 'bootstrap-vue'
import logoImg from '@/assets/images/logo/echope_small.png'
import { loadingOverlay, keyboardCode, devUtils, scanBarcode } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import DevButton from './dev/DevButton.vue'

export default {
  components: { BImg, EButton, BContainer, DevButton },

  mixins: [loadingOverlay, keyboardCode, devUtils, scanBarcode],

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    cancelable: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      logoImg,
    }
  },
  computed: {
    isCancelable() {
      const environment = window.env || 'local'
      if (environment !== 'production') {
        return true
      }
      return this.cancelable === true
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    onCancel() {
      // necessário para executar eventos que utilizem atalhos na tecla esc
      window.dispatchEvent(
        new KeyboardEvent('keydown', { key: this.keyCode.esc, code: this.keyCode.esc })
      )
      window.dispatchEvent(
        new KeyboardEvent('keyup', { key: this.keyCode.esc, code: this.keyCode.esc })
      )
      this.hideLoadingOverlay()
    },

    onKeyDown(event) {
      if (this.show !== true) {
        return
      }
      // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
      const allowedKeys = [this.keyCode.f11]
      if (this.isCancelable) {
        allowedKeys.push(this.keyCode.esc)
      }

      if (!allowedKeys.includes(event.key)) {
        console.debug('key', event.key, 'prevented')
        event.stopPropagation()
        event.preventDefault()
      }

      if (allowedKeys.some(key => key === this.keyCode.esc && key === event.code)) {
        this.hideLoadingOverlay()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 300ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.overlay-img-container {
  width: 25vw;
  height: 25vw;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;
  background: rgba($color: #b8c2cc, $alpha: 0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  .loading-overlay-label {
    max-width: 600px;
  }
}

@mixin dot-animation($delay) {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0s;
  animation: dot 1.3s infinite;
  animation-delay: $delay;
}

.one {
  @include dot-animation(0.2s);
}

.two {
  @include dot-animation(0.4s);
}

.three {
  @include dot-animation(0.7s);
}

.logo-animation {
  .img {
    height: 150px;
    width: 150px;
  }

  animation: beating 2s linear infinite;
}

@keyframes beating {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 1;
  }
}

@keyframes dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
