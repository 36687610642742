import { discountTypes, productDomains } from '../dominio'
import payBoxUtils from './pay-box-utils'

const getInitialSaleItemReturned = () => ({
  itemReturnedId: null,
  saleItemId: null,

  skuProduct: null,
  kitProduct: null,
  skuProductAssociated: null,

  quantity: 0,
  maxQuantity: 0,
  returnQuantityDamaged: 0,

  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  unitDiscount: 0,
  unitValue: 0,

  /** Data que o houve devolução do item */
  createdDate: null,
  /** Data de retorno do item (em saleItem é o atributo returnDate, em itemReturned é o atributo createdDate) */
  returnDate: null,
  returnDateLimit: null,
  willReturn: false,

  priceRuleSelected: null,
})

const buildSaleItemReturnedApiToSkuProduct = saleItemReturnedFromApi => ({
  ...saleItemReturnedFromApi.sku,
  classification: saleItemReturnedFromApi.classification,
  skuId: saleItemReturnedFromApi.sku.id,
  label: saleItemReturnedFromApi.sku.name,
})

// #region // REMOVE remover quando todos os caixas fizerem a migração do indexedDb
const OldSaleItemReturnedToNewSaleItemReturned = oldSaleItemReturnedList => {
  const { SALE } = productDomains.computed.productClassificationsEnum()

  const saleItemsReturnedMapped =
    oldSaleItemReturnedList?.map(oldItemReturned => {
      const { product } = oldItemReturned
      const saleItemReturned = {
        ...getInitialSaleItemReturned(),
        ...oldItemReturned,
        saleItemId: oldItemReturned.itemId,

        skuProduct: { ...product, productKitId: oldItemReturned.kit?.id },
        kitProduct: oldItemReturned.kit,

        quantity: oldItemReturned.amount,
        maxQuantity: oldItemReturned.maxAmount,
        returnQuantityDamaged: oldItemReturned.damagedAmount || 0,

        discountType: oldItemReturned.discountType,
        unitDiscount: oldItemReturned.unitDiscount,
        unitValue: oldItemReturned.unitValue || oldItemReturned.unitValueCalculated,

        returnDateLimit: oldItemReturned.returnDateLimit,
      }

      delete saleItemReturned.product

      const { priceRules } = payBoxUtils.buildSaleItemReturnPriceRules(saleItemReturned)

      const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
        forcedPriceRuleType: payBoxUtils.priceRuleTypes.RETURNED_ITEM,
        discountType: saleItemReturned.discountType,
        unitDiscount: saleItemReturned.unitDiscount,
        quantity: saleItemReturned.quantity,
        priceRules,
      })

      return {
        ...saleItemReturned,
        priceRules,
        priceRuleSelected,
      }
    }) || []

  const allItems = [...saleItemsReturnedMapped]

  return {
    returnedItems: allItems.filter(i => i.skuProduct.classification === SALE),
  }
}

// #endregion

const buildSaleItemReturnedFromApi = saleItemReturnedFromApi => {
  const saleItemReturned = {
    ...getInitialSaleItemReturned(),
    itemReturnedId: saleItemReturnedFromApi.id,
    saleItemId: saleItemReturnedFromApi.saleItemId,

    skuProduct: buildSaleItemReturnedApiToSkuProduct(saleItemReturnedFromApi),

    quantity: saleItemReturnedFromApi.quantity,
    maxQuantity: saleItemReturnedFromApi.quantity,

    returnDate: saleItemReturnedFromApi.createdDate,
    deliveredDate: saleItemReturnedFromApi.deliveredDate,
    createdDate: saleItemReturnedFromApi.createdDate,
    createdBy: saleItemReturnedFromApi.createdBy,

    discountType: saleItemReturnedFromApi.discountType,
    unitDiscount: saleItemReturnedFromApi.unitDiscount,
    unitValue: saleItemReturnedFromApi.unitValue,
  }

  const { priceRules } = payBoxUtils.buildSaleItemReturnPriceRules(saleItemReturned)

  const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
    forcedPriceRuleType: payBoxUtils.priceRuleTypes.RETURNED_ITEM,
    discountType: saleItemReturned.discountType,
    unitDiscount: saleItemReturned.unitDiscount,
    quantity: saleItemReturned.quantity,
    priceRules,
  })

  return {
    ...saleItemReturned,
    priceRules,
    priceRuleSelected,
  }
}

const buildSaleItemReturnedFromSaleItem = saleItems =>
  saleItems
    .flatMap(saleItem => {
      if (saleItem.skuProduct.kit) {
        return saleItem.kitItems.map(kitItem => ({
          ...kitItem,
          kitProduct: saleItem.skuProduct,
        }))
      }
      return [saleItem]
    })
    .flatMap(saleItem => {
      const lendingItems =
        saleItem.lendingProducts?.map(lendingItem => ({
          ...lendingItem,
          skuProductAssociated: saleItem.skuProduct,
          kitProduct: saleItem.kitProduct,
        })) || []

      return [saleItem, ...lendingItems]
    })
    .map(saleItem => {
      const itemToReturn = {
        ...getInitialSaleItemReturned(),
        saleItemId: saleItem.id,

        maxQuantity: saleItem.quantity,
        returnQuantityDamaged: saleItem.returnQuantityDamaged || 0,

        returnDate: saleItem.returnDate,
        returnDateLimit: saleItem.returnDateLimit,
        deliveredDate: saleItem.deliveredDate,
        createdDate: saleItem.createdDate,
        createdBy: saleItem.createdBy,

        skuProduct: saleItem.skuProduct,
        kitProduct: saleItem.kitProduct,
        skuProductAssociated: saleItem.skuProductAssociated,

        discountType: saleItem.discountType,
        unitDiscount: saleItem.unitDiscount,
        unitValue: saleItem.unitValue,
      }

      const { priceRules } = payBoxUtils.buildSaleItemReturnPriceRules(itemToReturn)
      const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
        forcedPriceRuleType: payBoxUtils.priceRuleTypes.RETURNED_ITEM,
        discountType: itemToReturn.discountType,
        unitDiscount: itemToReturn.unitDiscount,
        quantity: itemToReturn.quantity,
        priceRules,
      })

      return {
        ...itemToReturn,
        priceRules,
        priceRuleSelected,
      }
    })

const buildSaleItemReturnedAndReclaimed = (saleItems, saleItemsReturnedFromApi) => {
  const { SALE, RENT, LENDING } = productDomains.computed.productClassificationsEnum()

  const saleItemsReturnedMapped = saleItemsReturnedFromApi?.map(buildSaleItemReturnedFromApi) || []
  const saleItemsToReturnOrReclaim = buildSaleItemReturnedFromSaleItem(saleItems)

  const allItems = [...saleItemsReturnedMapped, ...saleItemsToReturnOrReclaim]

  return {
    returnedItems: allItems.filter(i => i.skuProduct.classification === SALE),
    returnedLendingItems: allItems.filter(
      i => i.skuProduct.classification === LENDING || i.skuProduct.classification === RENT
    ),
  }
}

const updateSaleItemReturned = (saleItemReturned, { quantity }) => {
  const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
    forcedPriceRuleType: payBoxUtils.priceRuleTypes.RETURNED_ITEM,
    discountType: saleItemReturned.discountType,
    unitDiscount: saleItemReturned.unitDiscount,
    quantity,
    priceRules: saleItemReturned.priceRules,
  })

  return {
    ...saleItemReturned,
    priceRuleSelected,
    quantity,
  }
}

export default {
  getInitialSaleItemReturned,
  buildSaleItemReturnedFromApi,
  buildSaleItemReturnedAndReclaimed,
  updateSaleItemReturned,
  // REMOVE
  OldSaleItemReturnedToNewSaleItemReturned,
}
