var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal_dev_settings",
        title: "DEV CONFIGS",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        size: "xl",
        centered: "",
        scrollable: "",
      },
      on: { hidden: _vm.hideModal },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-row",
                {
                  staticClass:
                    "w-100 d-flex justify-content-between align-items-center",
                },
                [
                  _vm.errorMessage
                    ? _c("b-col", { staticClass: "text-danger" }, [
                        _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                      ])
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal_dev_settings-btn_cancel",
                          variant: "outline-primary",
                          text: _vm.$t("Voltar"),
                          "text-shortcuts": ["ESC"],
                        },
                        on: { click: _vm.hideModal },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "FormulateForm",
        { ref: "formDevSettings", attrs: { name: "formDevSettings" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-card-actions",
                { attrs: { title: _vm.$t("PDV configs"), "no-actions": "" } },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("e-paybox-combo", {
                        staticStyle: { "min-width": "350px" },
                        attrs: {
                          id: "filter-payboxes",
                          "show-label": "",
                          label: "DEV (use-paybox-id)",
                          value: _vm.currentPayboxConfiguration.id
                            ? _vm.currentPayboxConfiguration.id.toString()
                            : null,
                          required: false,
                          placeholder: _vm.$t("Config Mac Address"),
                        },
                        on: { input: _vm.onUsePaybox },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card-actions",
                {
                  attrs: {
                    title: _vm.$t("Indexed Db venda"),
                    "no-actions": "",
                  },
                },
                [
                  _c("b-col", { staticClass: "d-flex", attrs: { md: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "devSaleStored",
                            name: "devSaleStored",
                            rowspan: "3",
                            type: "textarea",
                            label: "Venda atual",
                            readonly: "",
                          },
                          model: {
                            value: _vm.formData.pdvIdb.saleStored,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.pdvIdb, "saleStored", $$v)
                            },
                            expression: "formData.pdvIdb.saleStored",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("e-button", {
                          staticClass: "ml-2",
                          attrs: {
                            id: "devModal-reloadIndexedDbSale",
                            tabindex: "-1",
                            size: "sm",
                            variant: "primary",
                            icon: "arrow-clockwise",
                          },
                          on: {
                            click: function () {
                              return _vm.loadSaleIndexedDb()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { staticClass: "d-flex", attrs: { md: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "devSaleToUpdate",
                            name: "devSaleToUpdate",
                            rowspan: "5",
                            type: "textarea",
                            label:
                              "Venda a ser atualizada (colocar json no input)",
                          },
                          model: {
                            value: _vm.formData.pdvIdb.saleToUpdate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.pdvIdb, "saleToUpdate", $$v)
                            },
                            expression: "formData.pdvIdb.saleToUpdate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("e-button", {
                          staticClass: "ml-2",
                          attrs: {
                            id: "devModal-reloadIndexedDbSale",
                            tabindex: "-1",
                            size: "sm",
                            text: "Atualizar",
                            variant: "primary",
                          },
                          on: {
                            click: function () {
                              return _vm.updateSaleIndexedDb()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-card-actions",
                {
                  attrs: {
                    title: _vm.$t("Código de barras"),
                    "no-actions": "",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "devBarcode",
                          name: "devBarcode",
                          type: "text",
                          label: "Código de barras a ser escaneado",
                          instruction:
                            "Há casos que não dá pra digitar no campo por causa de bloqueio de teclado, mas dá pra abrir o modal onde é possível digitar e salvar no sessionStorage, na próxima vez que abrir o modal o valor estará salvo.",
                        },
                        model: {
                          value: _vm.formData.barcode.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData.barcode, "code", $$v)
                          },
                          expression: "formData.barcode.code",
                        },
                      }),
                      _c("e-button", {
                        staticClass: "mr-2",
                        attrs: {
                          id: "test-btn_barcode_local_storage",
                          text: _vm.$t("salvar na sessionStorage"),
                          size: "sm",
                          variant: "outline-primary",
                        },
                        on: { click: _vm.saveBarcodeOnSessionStorage },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "test-btn_barcode_scan",
                          text: _vm.$t("Simular scanner"),
                          size: "sm",
                          variant: "warning",
                        },
                        on: {
                          click: function () {
                            return _vm.simulateScan(_vm.formData.barcode.code)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }