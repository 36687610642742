import { roundDecimal } from '@/utils/number-utils'
import { contactUtils, payBoxUtils, saleItemReturnedUtils, saleItemUtils } from '../utils'
import personTypes from './person-types'
import address from '../address'
import paymentTypes from './payment-types'

export const getInitialConsignReturnForm = () => ({
  returnedItems: [],
  returnedLendingItems: [],
  value: 0,
  paymentMethod: null,
  observation: '',
})

export const getInitialPayBoxItemForm = () => ({
  quantity: 1,
  observation: '',
})

const getInitialReceiptForm = () => ({
  id: null,
  paymentMethod: {
    id: null,
    name: '',
    method: '',
  },
  paymentMethodInterface: paymentTypes.data().paymentMethodInterfaceEnum.TEF,
  value: 0,
  canceled: false,
  change: 0,
  nsu: null,
  documentTitular: null,
  cardBrand: null,
})

const getInitialDeliveryForm = () => ({
  address: address.data().address,
  observation: '',
  addressSelected: 'other',
  deliveryTaxDiscountValue: 0,
  deliveryTax: 0,
  deliveryStatus: null,
  deliveryDate: null,
})

const getInitialPalmBeerCardData = () => ({
  customer: { id: null },
})

const getInitialCancelationSaleData = () => ({
  cancelationUser: { id: null, name: null },
  cancelationDate: null,
  cancelationReason: null,
})

const getInitialPayBoxSale = () => ({
  id: null,
  uuid: null,
  barTabConsumption: {
    id: null,
    barTab: {
      id: null,
      number: '',
    },
  },
  grossValue: 0,
  netValue: 0,
  comission: 0,
  comissionFee: null,
  paymentStarted: false,
  items: [],
  invoiceReturn: null,
  offline: false,
  priceTable: null,
  itemForm: getInitialPayBoxItemForm(),
  clientForm: {
    id: null,
    personType: personTypes.computed.personTypeEnum().CONSUMER,
    landLine: '',
    mobilePhone: '',
    email: '',
    site: '',
    observation: '',
    document: '', // represent CNPJ or CPF
    name: '', // represent name or TradingName
    priceTable: {},

    gender: '',
    birthdate: null,

    companyName: '',
    stateRegistrationType: '',
    stateRegistration: '',
    municipalInscription: '',

    address: address.data().address,
    addressList: [],
  },
  paymentForm: {
    discount: 0,
    receipts: [],
    change: 0,
    balance: 0,
  },
  pendingPaymentValue: 0,
  isDelivery: false,
  isConsigned: false,
  consignedMaxReturnValue: 0,
  deliveryForm: getInitialDeliveryForm(),
  returnForm: getInitialConsignReturnForm(),
  returnSale: {
    value: 0,
  },
  palmBeerData: getInitialPalmBeerCardData(),
  invoice: null,
  user: null,
  status: null,
  payBoxId: null,
  store: null,
  cancelationData: getInitialCancelationSaleData(),
})

const payBoxSale = {
  data() {
    return {
      getInitialPayBoxSale,
    }
  },

  computed: {},

  methods: {
    getSubtotalSaleWithoutDiscount(saleItems) {
      const result = saleItems.reduce((total, item) => {
        const grossValue = item.isKit
          ? item.priceRuleSelected?.priceInfo?.localNetValue
          : item.priceRuleSelected?.priceInfo?.localGrossValue
        return total + grossValue || 0
      }, 0)
      return roundDecimal(result)
    },
    getTotalItemsDiscount(saleItems) {
      const result = saleItems.reduce(
        (total, item) => total + item.priceRuleSelected?.priceInfo?.localDiscountTotal || 0,
        0
      )
      return roundDecimal(result)
    },
    /**
     * Pegar a quantidade de determinado produto na venda (já pega cenários de produtos iguais separados e dentro de kit)
     */
    getProductQuantityInSale(saleItems, skuId, options = { originalOrder: false }) {
      const getQuantity = item =>
        (options.originalOrder ? item.orderQuantity : item.quantity) || 0

      const items = options.originalOrder ? saleItems?.filter(i => i.id) : saleItems
      const result = items?.reduce((qttTotal, item) => {
        if (skuId === item.skuProduct?.skuId) return qttTotal + getQuantity(item)

        const qttProductInKitItems = item.skuProduct?.kitItems?.reduce((sum, ki) => {
          if (ki.skuProduct?.skuId === skuId) return sum + getQuantity(ki)
          return sum
        }, 0)
        if (qttProductInKitItems > 0) return qttTotal + qttProductInKitItems

        return qttTotal
      }, 0)

      return result || 0
    },

    getKitQuantityInSale(saleItems, productKitId, options = { originalOrder: false }) {
      const getQuantity = item =>
        (options.originalOrder ? item.orderQuantity : item.quantity) || 0
      const saleItemKits = saleItems.filter(i => i.skuProduct.kitItems?.length > 0)
      const items = options.originalOrder ? saleItemKits?.filter(i => i.id) : saleItemKits

      const result = items.reduce((sum, ki) => sum + getQuantity(ki), 0)
      return result
    },

    prepareSale(saleData) {
      const {
        id: saleDataId,
        uuid,
        barTabConsumption,
        customer,
        priceTable,
        discountType: saleDiscountType,
        discount: saleDiscount,
        delivery: isDelivery,
        consigned: isConsigned,
        consignedMaxReturnValue,
        deliveryTaxDiscountValue,
        deliveryTax,
        deliveryObservation,
        deliveryAddress,
        deliveryDate,
        deliveryStatus,
        offline,
        items,
        itemsReturned,
        itemKits,
        payments,
        grossValue: saleGrossValue,
        netValue: saleNetValue,
        comission,
        returnSale,
        invoice,
        user,
        cancelationUser,
        cancelationDate,
        cancelationReason,
        status,
        payBoxId,
        store,
        pendingPaymentValue,
        origin,
        reason,
      } = saleData

      const { cellphone, phone } = contactUtils.getPricipalMobileAndLandline(customer.telephones)
      const defaultData = getInitialPayBoxSale()

      const receipts = payments.map(p => ({
        ...getInitialReceiptForm(),
        ...p,
      }))
      const itemsOfSale = saleItemUtils.buildSaleItemsAndKitsFromApi(items, itemKits, itemsReturned)
      const productTotalValue = itemsOfSale.reduce(
        (total, i) => total + i.priceRuleSelected.priceInfo.localNetValue,
        0
      )

      const formatedData = {
        ...defaultData,
        invoice,
        user,
        status,
        origin,
        payBoxId,
        store,
        id: saleDataId,
        uuid,
        barTabConsumption,
        comission,
        comissionFee: barTabConsumption?.comissionFee ?? null,
        grossValue: saleGrossValue,
        netValue: saleNetValue,
        pendingPaymentValue,
        priceTable,
        offline,
        reason,
        items: itemsOfSale,
        itemsReturned,
        returnSale,
        // tipo do desconto está apenas para valor (quando vier em porcentagem, é convertido para valor)
        paymentForm: {
          ...defaultData.paymentForm,
          discount: payBoxUtils.getDiscountValue({
            discountType: saleDiscountType,
            discount: saleDiscount,
            value: productTotalValue,
          }),
          balance: 0,
          receipts,
        },
        isDelivery,
        isConsigned,
        consignedMaxReturnValue: consignedMaxReturnValue || 0,
        deliveryForm: {
          ...defaultData.deliveryForm,
          address: deliveryAddress,
          observation: deliveryObservation,
          addressSelected: '',
          deliveryTax,
          deliveryTaxDiscountValue,
          deliveryStatus,
          deliveryDate,
        },
        clientForm: {
          ...defaultData.clientForm,
          ...customer,
          landLine: phone,
          mobilePhone: cellphone,
          site: customer.siteUrl,
          address:
            customer.addresses.length > 0 ? customer.addresses[0] : defaultData.clientForm.address,
          addressList: customer.addresses || [],
        },
        cancelationData: {
          ...defaultData.cancelationData,
          user: cancelationUser,
          date: cancelationDate,
          reason: cancelationReason,
        },
      }

      if (isConsigned) {
        formatedData.returnForm = this.prepareSaleReturnedData(
          formatedData.items,
          formatedData.itemsReturned,
          returnSale
        )
      }

      return formatedData
    },

    prepareSaleReturnedData(saleItems, itemsReturnedFromApi, returnSaleFromApi) {
      const { returnedItems, returnedLendingItems } =
        saleItemReturnedUtils.buildSaleItemReturnedAndReclaimed(saleItems, itemsReturnedFromApi)

      const defaultReturnForm = getInitialConsignReturnForm()
      return {
        ...defaultReturnForm,
        returnedItems,
        returnedLendingItems,
        value: returnSaleFromApi?.value ?? defaultReturnForm.value,
        paymentMethod: returnSaleFromApi?.paymentMethod ?? defaultReturnForm.paymentMethod,
        observation: returnSaleFromApi?.observation ?? defaultReturnForm.observation,
      }
    },

    // TODO colocar esses métodos no saleItemUtils
    /** inicialmente utilizado para formar a key do dicionario de itens com quantidade */
    getSaleProductKey({ skuId, promotionId, kitId }) {
      return `sku${skuId || 0}_promo${promotionId || 0}_kit${kitId || 0}`
    },

    findProductInSaleItem(product) {
      return saleItem =>
        saleItem.skuProduct.id === product.id &&
        saleItem.skuProduct.kit === product.kit &&
        saleItem.skuProduct.promotionId === (product.promotionId ?? null)
    },
  },
}

export default payBoxSale
