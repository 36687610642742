<template>
  <div v-if="mxIsDevelopment">
    <button @click="openDevModal()">
      Abrir dev config
    </button>
  </div>
</template>

<script>
import { devUtils } from '@/mixins'
import EventBus from '@/utils/EventBus'

export default {
  mixins: [devUtils],
  methods: {
    openDevModal() {
      EventBus.$emit('open-dev-modal')
    }
  }
}
</script>
